
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import App from '../App';

hydrateRoot(document.getElementById('root'), <App pageData={{...JSON.parse(decodeURIComponent(window.PAGE_DATA))}}/>);



if (module.hot) {
  module.hot.accept();
}
