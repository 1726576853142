

/**
 * @type {import('@aaa/nuxeo-react-library/module.client').NuxeoReactFrontendLoggingConfig}
 */
export default  {

  logPageView: (pageData) => {
    console.log("Logging Page View", pageData);
  },

  components:{

    logBookingWidgetSubmitted: (pageData, sectionData) => {
      console.log("Logging Booking Widget Submitted", pageData, sectionData);
    },
    logMenuItemClicked: (pageData, sectionData, menuItemText, destinationUrl) => {   
      console.log("Logging Menu Item Clicked", pageData, sectionData, menuItemText, destinationUrl);
    },
    logListItemClicked: (pageData, sectionData, listItemText, destinationUrl) => {
      console.log("Logging List Item Clicked", pageData, sectionData, listItemText, destinationUrl);
    },
    logListTitleClicked: (pageData, sectionData, listTitleText, destinationUrl) => {
      console.log("Logging List Title Clicked", pageData, sectionData, listTitleText, destinationUrl);
    },
    logHyperlinkClicked: (pageData, sectionData, linkText, destinationUrl) => {
      console.log("Logging Hyperlink Clicked", pageData, sectionData, linkText, destinationUrl);
    },
    logCardClicked: (pageData, sectionData, cardData) => { 
      console.log("Logging Card Clicked", pageData, sectionData, cardData);
    },
    logImageCarouselNext: (pageData, sectionData) => {
      console.log("Logging Image Carousel Next", pageData, sectionData);
    },
    logImageCarouselPrevious: (pageData, sectionData) => {
      console.log("Logging Image Carousel Previous", pageData, sectionData);
    },
    logButtonClicked: (pageData, sectionData, buttonText, destinationUrl) => {
      console.log("Logging Button Clicked", pageData, sectionData, buttonText, destinationUrl);
    },
    logAccordionClicked: (pageData, sectionData, accodionTitle, accordionIsOpen) => {
      console.log("Logging Accordion Clicked", pageData, sectionData, accodionTitle, accordionIsOpen);
    },
    logBreadcrumbClicked: (pageData, breadcrumb, destinationUrl) => {
      console.log("Logging Breadcrumb Clicked", pageData, breadcrumb, destinationUrl);
    }
  },

  memberValidation:{
    logMemberValidationFailure: (pageData) => {
      console.log("Logging Member Validation Failure", pageData);
    },
    logMemberValidationPrompt: (pageData) => {
      console.log("Logging Member Validation Prompt", pageData);
    },
    logMemberValidationResponse: (pageData) => {
      console.log("Logging Member Validation Response", pageData);
    }
  }

}
