
import React from 'react';
import NuxeoReactFrontend from '@aaa/nuxeo-react-library/module.client';
import { environmentConfig } from './config/environment.config';
import {default as MyLoggingConfig} from './config/logging.config';

/**
 * @typedef {object} AppProps
 * @property {import('@aaa/nuxeo-react-library/module.client').NuxeoReactPageData} pageData
 * @property {string} error 
 */

/**
 * 
 * @param {AppProps} props 
 * @returns JSX.Element
 */
export default function App({pageData,error}){

    return <NuxeoReactFrontend error={error} environmentConfig={environmentConfig}  loggingConfig={MyLoggingConfig} pageData={pageData}/>
}

